import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import i18next from "i18next";
import { useHistory } from "react-router-dom";

import UserChatItem from "./UserChatItem";
import EmptyMessage from "../EmptyMessage";
import { getChat, getIsNewChat } from "redux/chatReceived/selectors";
import { getCanSendMessage, getIsUserSubscribed, getMessages } from "redux/chatMessages/selectors";
import {
	fetchChatMessages,
	leaveChatMessagesScreen,
	sendChatMessage,
} from "redux/chatMessages/actions";
import { hasTyping } from "redux/chatMeta/selectors";
import Typing from "../Typing";
import Footer from "./Footer";
import { getUserProfile } from "redux/profile/selectors";
import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import { getIsManualyPromotedUser } from "redux/promotions/selectors";
import ChatMetaService from "services/chatMeta";
import { cancelActiveUser } from "redux/chat/actions";
import { cancelDeleteChat } from "redux/chatReceived/actions";
import usePopups from "hooks/usePopups";
import { getUserSubscriptionType } from "redux/subscription/selectors";
import Subscription from "consts/subscription";
import { checkRelevantQuota } from "helpers/quota";
import { getUserQuotaStatus } from "redux/general/selectors";

export default ({ isFromCardUserModal = false }) => {
	const dispatch = useDispatch();
	const [simplebarContent, setSimplebarContent] = useState();
	const userQuotaStatus = useSelector(getUserQuotaStatus);
	const loggedInUserProfile = useSelector(getUserProfile);
	const active_userId = useSelector((state) => state.chat.active_userId);
	const chatID = useSelector(getChat(active_userId));
	const isTyping = useSelector(hasTyping(chatID));
	const chatMessages = useSelector(getMessages(chatID, active_userId));
	const canSendMessage = useSelector(getCanSendMessage);
	const isUserSubscribed = useSelector(getIsUserSubscribed);
	const isNewChat = useSelector(getIsNewChat(active_userId));
	const isManualyPromoted = useSelector(getIsManualyPromotedUser(active_userId));
	const [emptyMessage, setEmptyMessage] = useState("");
	const activeUserProfile = useSelector(getUserPublicProfile(active_userId)) || {};
	const {
		showNonSubscriberToSubscriberPopup,
		showNonSubscriberToNonSubscriberPopup,
		showSubscriberLimitMessagesPopup,
		showNonSubscriberLimitMessagesPopup,
		showQuotaPopup,
	} = usePopups();
	const subscriptionType = useSelector(getUserSubscriptionType);
	const history = useHistory();
	const currentPath = history.location.pathname;
	const isLoggedInUserSubscribed =
		subscriptionType === Subscription.PAID || subscriptionType === Subscription.FREE;
	const isCollageSubscribed =
		activeUserProfile.subscription &&
		(activeUserProfile.subscription === Subscription.PAID ||
			activeUserProfile.subscription === Subscription.FREE) &&
		!activeUserProfile.subscriptionFreezed;
	const canRead = useSelector((state) => !!state.chat?.canRead) || false;

	useEffect(() => {
		setSimplebarContent(
			document.querySelector(
				isFromCardUserModal
					? ".modal-body .user-chat .simplebar-content-wrapper"
					: ".user-chat .simplebar-content-wrapper"
			)
		);
		return () => {
			currentPath.indexOf("messages") === -1 && dispatch(cancelActiveUser());
		};
	}, []);

	useEffect(() => {
		if (!active_userId) return;
		dispatch(fetchChatMessages(active_userId, chatID));
		simplebarContent && simplebarContent.classList.add("fade-out");
		setEmptyMessage("");
		setTimeout(() => {
			simplebarContent && simplebarContent.classList.remove("fade-out");
			setEmptyMessage(isManualyPromoted ? i18next.t("Empty Message.Manualy Promoted") : "");
		}, 500);

		return () => {
			//TODO - Check if it is possible no chatId and delete 'chatID &&' if need
			//fail after leaving user without chatId
			chatID && ChatMetaService.updateTyping(active_userId, chatID, null);
			dispatch(leaveChatMessagesScreen());
		};
	}, [active_userId, simplebarContent]);

	useEffect(() => {
		simplebarContent && (simplebarContent.scrollTop = simplebarContent.scrollHeight);
	}, [chatMessages]);

	const _onSend = async (data, isAudio = false) => {
		if (userQuotaStatus && (await checkRelevantQuota())) return showQuotaPopup();

		cancelDeleteChat(active_userId);
		if (canSendMessage) {
			if (isAudio) {
				dispatch(sendChatMessage(chatID, "", active_userId, isNewChat, false, false, "", data));
			} else dispatch(sendChatMessage(chatID, data, active_userId, isNewChat));
			!isLoggedInUserSubscribed && isCollageSubscribed && showNonSubscriberToSubscriberPopup();
			!isLoggedInUserSubscribed && !isCollageSubscribed && showNonSubscriberToNonSubscriberPopup();
		} else {
			isUserSubscribed && showSubscriberLimitMessagesPopup();
			!isUserSubscribed && showNonSubscriberLimitMessagesPopup();
		}
	};

	const isShowIcon = (items, chatMessage, index) => {
		if (!canRead && chatMessage.privateBlur) {
			return index === items.findIndex((obj) => obj.privateBlur);
		}
		if (!canRead && chatMessage.generalBlur) {
			return index === items.findIndex((obj) => obj.generalBlur);
		}
		if (chatMessage.isMassletter) {
			return index === items.findIndex((obj) => obj.isMassletter);
		}
		return false;
	};
	return (
		<>
			<SimpleBar
				style={{ maxHeight: "100%" }}
				className="chat-conversation p-3 p-lg-4"
				id="messages">
				<ul className="list-unstyled mb-0">
					{chatMessages?.length > 0 ? (
						chatMessages.map((chatMessage, index) => {
							const showIcon = isShowIcon(chatMessages, chatMessage, index);
							return (
								(!chatMessage.isPromotion ||
									(chatMessage.isPromotion &&
										chatMessage.uid !== loggedInUserProfile.uid &&
										chatMessages.length === 1)) && (
									<UserChatItem
										key={chatMessage.id}
										index={index}
										currentMessage={chatMessage}
										chatMessages={chatMessages}
										showIcon={showIcon}
									/>
								)
							);
						})
					) : (
						<EmptyMessage message={emptyMessage} />
					)}
					{isTyping && (
						<li>
							<div className="conversation-list">
								<div className="ctext-wrap-content">
									<Typing />
								</div>
							</div>
						</li>
					)}
				</ul>
			</SimpleBar>
			<Footer
				key={active_userId}
				chatID={chatID}
				userUid={active_userId}
				gender={loggedInUserProfile?.gender}
				onSend={_onSend}
				user={activeUserProfile}
			/>
		</>
	);
};

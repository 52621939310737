import { createSelector } from "reselect";

export const getSystemAppSettings = (state) => state.system.data;

export const getAppDetails = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings.appDetails
);

export const getSubscriptionLimits = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings.subscriptionLimits
);

export const getSubscriptionsIsOn = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings.subscriptionsIsOn
);

export const getMassletterPeriodDays = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings.massletterPeriodDays
);

export const getIsPasswordLoginHidden = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings.passwordLoginHidden === true
);

export const getIsVipRibbonHidden = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings.isVipRibbonHidden === true
);

export const getShowMaintenancePage = createSelector(getAppDetails, (appDetails) => {
	return appDetails.showMaintenancePage;
});

export const getUserMustUpdateFromStore = createSelector(
	getAppDetails,
	(appDetails) => appDetails.userMustUpdateFromStore
);

export const getUserMustUpdateToVersions = createSelector(
	getAppDetails,
	(appDetails) => appDetails.versions
);

export const getGoogleIapIsOn = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings.googleIapIsOn
);
export const getEffortButtonTitle = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings.effortButtonTitle
);
export const getUseAlternativeLogin = createSelector(
	getSystemAppSettings,
	(appSettings) => !!appSettings?.useAlternativeLogin
);

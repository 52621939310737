import ChatAvatar from "components/ChatAvatar";
import DayDivider from "components/DayDivider";
import moment from "moment";
import SimpleBar from "simplebar-react";
import { timeFormat } from "consts/date";
import { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { getUserProfile } from "redux/profile/selectors";
import { useSelector } from "react-redux";
import broadcastService from "services/broadcastService";
import { CircularProgress } from "@mui/material";
import PublicProfileService from "services/publicProfile";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";

export default function ChanelBody() {
	const userProfile = useSelector(getUserProfile);
	const [broadcastMessages, setBroadcastMessages] = useState([]);

	useEffect(async () => {
		if (!userProfile?.uid) return;

		await broadcastService.getUserBroadcast().then((data) => {
			if (!data) return;
			setBroadcastMessages(Object.values(data || {}).filter((bro) => !!bro));
		});
	}, [userProfile]);

	return (
		<div>
			<SimpleBar className="chat-conversation">
				<ul className="list-unstyled mb-0">
					{broadcastMessages.map((chatMessage, index) => {
						return (
							<ChanelChatItem
								key={chatMessage?.id}
								index={index}
								currentMessage={chatMessage}
								broadcastMessages={broadcastMessages}
							/>
						);
					})}
				</ul>
			</SimpleBar>
		</div>
	);
}

const ChanelChatItem = ({ index, currentMessage, broadcastMessages }) => {
	const ref = useRef(null);
	const previousMessage = broadcastMessages[index - 1];
	const nextMessage = broadcastMessages[index + 1];

	const getMsgContent = () => {
		switch (currentMessage?.type) {
			case "options":
				return <OptionsMsg currentMessage={currentMessage} />;
			case "confirmTelegram":
				return <DynamicOptionsMsg currentMessage={currentMessage} type={currentMessage?.type} />;
			default:
				return <BasicMsg currentMessage={currentMessage} />;
		}
	};

	useEffect(() => {
		if (!!ref.current) ref.current?.scrollIntoView();
	}, [ref.current]);

	return (
		<li
			className={`left ${currentMessage?.classMsg ? `${currentMessage?.classMsg}` : ""}`}
			ref={ref}>
			<DayDivider currentMessage={currentMessage} previousMessage={previousMessage} />
			<div className="conversation-list">
				{/* <ChatAvatar currentMessage={currentMessage} nextMessage={nextMessage} /> */}
				<div className="user-chat-content">{getMsgContent()}</div>
			</div>
		</li>
	);
};

const DynamicOptionsMsg = ({ currentMessage, type }) => {
	const [answer, setAnswer] = useState(currentMessage?.userAns ?? "");
	const [serverAns, setServerAns] = useState(currentMessage?.serverAns ?? {});
	const [loading, setLoading] = useState(false);
	const { options } = currentMessage;

	const handleBtnClicked = async (ans) => {
		setLoading(true);
		await broadcastService
			.sendDynamicBroadcastAnswer(currentMessage.key, ans.key, type)
			.then((res) => {
				if (res?.data?.success) {
					if (res.data?.userAns) setAnswer(res.data?.userAns);
					if (res.data?.serverAns) setServerAns(res.data?.serverAns);
				}
				setLoading(false);
			})
			.catch((e) => {
				console.log(e);
				setLoading(false);
			});
	};

	return (
		<div className={`${answer || !!serverAns?.text ? "commentMsg " : ""}ctext-wrap`}>
			<div className="ctext-wrap-content">
				<div className="commentSpan">
					<span className="mb-0 " dangerouslySetInnerHTML={{ __html: currentMessage?.text }} />
					{currentMessage?.image && <img src={currentMessage.image} />}
				</div>
				{!serverAns?.text && (
					<>
						{answer ? (
							<div className="mb-0">{`התשובה שלך: ${answer?.text}`}</div>
						) : (
							<div className="wrapOptions">
								{!!loading ? (
									<CircularProgress size={30} className="text-primary" />
								) : (
									<>
										{options &&
											options.map((option, index) => (
												<Button
													key={index}
													className="options"
													color="primary"
													onClick={() => handleBtnClicked(option)}>
													<span>{option?.text}</span>
												</Button>
											))}
									</>
								)}
							</div>
						)}
					</>
				)}
				{serverAns?.text && (
					<div className="matchmakerAns" dangerouslySetInnerHTML={{ __html: serverAns?.text }} />
				)}
				{serverAns?.image && <img src={serverAns?.image} />}

				<p className="chat-time mb-0">
					<span className="align-middle">
						{moment(currentMessage.timeStamp).locale("en").format(timeFormat)}
					</span>
				</p>
			</div>
		</div>
	);
};
const OptionsMsg = ({ currentMessage }) => {
	const [answer, setAnswer] = useState(currentMessage?.userAns ?? "");
	const [matchmakerAns, setMatchmakerAns] = useState(currentMessage?.matchmakerAns ?? {});
	const [loading, setLoading] = useState(false);
	const { options } = currentMessage;
	const [suggestUser, setSuggestUser] = useState({});
	const { showUserCardPopup, showUserProfileSidebarModal } = usePopups();

	const showUserDetails = () => {
		!isMobile() ? showUserCardPopup(suggestUser) : showUserProfileSidebarModal(suggestUser);
	};
	const getTextWithoutCard = (text) => {
		if (!text) return "";
		const removeTextFrom = "<b>לכרטיס של";

		if (text.includes(removeTextFrom)) {
			text = text.substring(0, text.indexOf(removeTextFrom));
		}
		return text;
	};

	const handleBtnClicked = async (ans) => {
		setLoading(true);
		await broadcastService
			.sendBroadcastAnswer(currentMessage.key, ans.key)
			.then((res) => {
				if (res?.data?.success) {
					if (res.data?.userAns) setAnswer(res.data?.userAns);
					if (res.data?.matchmakerAns) setMatchmakerAns(res.data?.matchmakerAns);
				}
				setLoading(false);
			})
			.catch((e) => {
				console.log(e);
				setLoading(false);
			});
	};

	useEffect(async () => {
		if (!currentMessage?.suggestUid) return;
		const suggestUserProfile = await PublicProfileService.fetchProfile(currentMessage?.suggestUid);
		setSuggestUser(suggestUserProfile || {});
	}, []);

	return (
		<div className={`${answer || !!matchmakerAns?.text ? "commentMsg " : ""}ctext-wrap`}>
			<div className="ctext-wrap-content">
				<div className="commentSpan">
					<span
						className="mb-0 "
						dangerouslySetInnerHTML={{ __html: getTextWithoutCard(currentMessage?.text) }}
					/>
					{!!suggestUser?.name && currentMessage?.suggestUid && (
						<div className="moreDetailsPopup" onClick={showUserDetails}>
							{`לכרטיס של ${suggestUser?.name} `}
							<div style={{ textDecoration: "underline", display: "inline" }}>{`יש ללחוץ כאן`}</div>
						</div>
					)}
					{currentMessage?.image && <img src={currentMessage.image} />}
				</div>
				{!matchmakerAns?.text && (
					<>
						{answer ? (
							<div className="mb-0">{`התשובה שלך: ${answer?.text}`}</div>
						) : (
							<div className="wrapOptions">
								{!!loading ? (
									<CircularProgress size={30} className="text-primary" />
								) : (
									<>
										{options &&
											options.map((option, index) => (
												<Button
													key={index}
													className="options"
													color="primary"
													onClick={() => handleBtnClicked(option)}>
													<span>{option?.text}</span>
												</Button>
											))}
									</>
								)}
							</div>
						)}
					</>
				)}
				{matchmakerAns?.text && (
					<div
						className="matchmakerAns"
						dangerouslySetInnerHTML={{ __html: matchmakerAns?.text }}
					/>
				)}
				{matchmakerAns?.image && <img src={matchmakerAns?.image} />}
				<div>
					לשים לב, אם שניכם מסמנים לסמארט בוט שאתם בעניין. מספר הפלאפון עובר לשניכם ישירות. מהיר
					יעיל ופשוט. בהצלחה :)
				</div>
				<p className="chat-time mb-0">
					<span className="align-middle">
						{moment(currentMessage.timeStamp).locale("en").format(timeFormat)}
					</span>
				</p>
			</div>
		</div>
	);
};
const BasicMsg = ({ currentMessage }) => {
	const renderMessageContent = () => {
		return (
			<div className="commentSpan">
				<span className="mb-0 " dangerouslySetInnerHTML={{ __html: currentMessage?.text }} />
				{currentMessage?.image && <img src={currentMessage.image} />}
			</div>
		);
		// return <span className="mb-0" dangerouslySetInnerHTML={{ __html: currentMessage?.text }} />;
	};
	return (
		<div className="ctext-wrap">
			<div className="ctext-wrap-content">
				{renderMessageContent()}
				<p className="chat-time mb-0">
					<span className="align-middle">
						{moment(currentMessage.timeStamp).locale("en").format(timeFormat)}
					</span>
				</p>
			</div>
		</div>
	);
};
